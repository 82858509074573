import React from 'react'

import { FormattedMessage } from 'react-intl'

export default class WhatYouGet extends React.Component {
  render () {
    return (
      <div className='row landing_row' id='what_you_get'>
        <div className='col'>
          <div className='row'>
            <div className='col h2 text-center'>
              <FormattedMessage id='what_you_get' />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center'>
              {/* <img className="d-block mx-auto" src="res/teaser_16bit.jpg" /> */}
              <div className='h5'>
                <FormattedMessage id='what_you_get.lifetime_access' />
              </div>
              <FormattedMessage id='what_you_get.lifetime_access.text' />
            </div>
            <div className='w-100 d-lg-none' />

            <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center'>
              {/* <img className="d-block mx-auto" src="res/teaser_ui.jpg" /> */}
              <div className='h5'>
                <FormattedMessage id='what_you_get.free_updates' />
              </div>
              <FormattedMessage id='what_you_get.free_updates.text' />
            </div>
            <div className='w-100 d-lg-none' />

            <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center'>
              {/* <img className="d-block mx-auto" src="res/teaser_speed.jpg" /> */}
              <div className='h5'>
                <FormattedMessage id='what_you_get.licenses' />
              </div>
              <FormattedMessage id='what_you_get.licenses.text' />
            </div>
            <div className='w-100 d-lg-none' />

            <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center'>
              {/* <img className="d-block mx-auto" src="res/teaser_preview.jpg" /> */}
              <div className='h5'>
                <FormattedMessage id='what_you_get.tutorials' />
              </div>
              <FormattedMessage id='what_you_get.tutorials.text' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
