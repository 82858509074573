import React from 'react'

import VideoRow from '../components/videorow.js'
import WhatYouGet from '../components/whatyouget.js'

import IndexPage_Header from '../components/main/previewer/indexpage_header.js'
import ModalSignIn from '../components/main/signin/modal_signin.js'

import Layout from '../components/layout/rootLayout.js'

const PromoPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div>
      <div id='previewer' className='container-fluid coverPhoto'>
        <nav className='navbar navbar-toggleable-md navbarArcPanel'>
          <button
            className='navbar-toggler navbar-toggler-right'
            type='button'
            data-toggle='collapse'
            data-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <a className='navbar-brand' href='/'>
            ARCPanel Homepage
          </a>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav' />
          </div>
        </nav>
        <IndexPage_Header />
      </div>
      <div className='container-fluid' id='text_content'>
        <VideoRow />
        <WhatYouGet landing />
      </div>
      <ModalSignIn />
    </div>
  </Layout>
)

export default PromoPage
