import React from 'react'
import YouTube from './youtube.js'
import { injectIntl } from 'react-intl'

const VideoRow = () => (
  <div className='row landing_row' id='overview'>
    <div className='col' />
    <div className='col-sm-12 col-md-12 col-lg-9 col-xl-8'>
      <div className='row'>
        <div className='col text-center overview'>
          <YouTube
            video_ru='vhUf2oi73eg'
            video_en='po-T03Yl3x0'
          />
        </div>
      </div>
    </div>
    <div className='col' />
  </div>
)
export default injectIntl(VideoRow)
